<template>
    <div class="w-full gradient-box h-[266px] rounded-lg">
        <div class="carousel relative overflow-hidden w-full h-[266px] rounded-xl">
            <div
                class="carousel-indicators absolute bottom-0 flex  bg-profile-deposit h-[266px] w-full justify-center items-center">
                <ol class="flex h-full items-end pb-3 w-4/12 justify-center">
                    <li v-for="(img, i) in props.images" :key="i" :class="active == i ? 'bg-gray-600' : 'bg-gray-300'"
                        class="md:w-2 md:h-2 rounded-full cursor-pointer mx-2"></li>
                </ol>
            </div>

            <div class="carousel-inner relative w-full h-full">
                <div class="absolute top-[91%]
              left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"><button
                        v-if="dashboardStore.modal.modalOpen == false" style="border-radius: 12px"
                        class=" w-[189px] h-[33px] font-bold text-xs bg-gradient-to-b text-white from-button-yellow-1 to-button-yellow-2 px-3"
                        @click="setModal(true, 'deposit-modal')">
                        DEPOSIT
                    </button></div>
                <div v-for="(img, i) in props.images" :key="i" :class="[
                    'carousel-item',
                    {
                        active: active === i,
                        prev: prevActive === i,
                        next: nextActive === i
                    }
                ]" class="absolute inset-0 transition-transform duration-[5000ms] ease-in-out"
                    :style="getSlideStyle(i)">
                    <img class="image-canva block w-full h-full object-cover" :src="img" :alt="`Slide ${i + 1}`" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDashboardStore } from '@/store/dashboardStore';
import { onMounted, onUnmounted, ref } from 'vue'

// Define props
const props = defineProps(['images'])
const dashboardStore = useDashboardStore()
const active = ref(0)
const prevActive = ref(null)
const nextActive = ref(null)
const setModal = (val, name) => {
    dashboardStore.setModalOpen(val, name)
    if (name == 'register-modal') dashboardStore.setHeader('Sign Up')
    else if (name == 'login-modal' || name == 'forget-modal') dashboardStore.setHeader('Login')
    else if (name == 'deposit-modal') { dashboardStore.setSelectedTab(3), dashboardStore.setHeader('Deposit') }
    else if (name == 'search-modal') dashboardStore.setHeader('Search')
}
const next = () => {
    prevActive.value = active.value
    active.value = active.value < props.images.length - 1 ? active.value + 1 : 0
    nextActive.value = active.value
}


const getSlideStyle = index => {
    if (active.value === index) return { transform: 'translateX(0%)', zIndex: 1 }
    if (prevActive.value === index) return { transform: 'translateX(-100%)', zIndex: 1 }
    return { transform: 'translateX(100%)', zIndex: 0 }
}

onMounted(() => {
    setInterval(() => {
        next()
    }, 3000)
})

onUnmounted(() => {
    clearInterval()
})
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.gradient-box {
    border-radius: 20px;
    /* Adjust this value to change the border radius */
    padding: 2px;
    /* Adjust to match the border width */
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-box {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    /* Slightly less than the outer box to fit inside */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    text-align: center;
}

.left-full {
    left: -100%;
}

.carousel {
    position: relative;
}

.image-canva {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.carousel-inner {
    position: relative;
    height: 100%;
}

.carousel-item {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 1s ease-in-out;
}

.carousel-item img {
    display: block;
    width: 100%;
    height: 100%;
    object-cover: cover;
}
</style>
