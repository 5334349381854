<template>
    <nav class="bottom-menu z-50 ">
        <button @click="selectTab(0)">
            <div class="flex justify-center"><svg :fill="dashboardStore.selectedTab == 0 ? '#E3B925' : 'white'"
                    width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">

                    <path d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
                </svg>
                <!-- Wallet icon -->
            </div>

            <div class="font-bold" :class="dashboardStore.selectedTab == 0 ? 'text-[#E3B925]' : 'text-white'">Home</div>
        </button>
        <button @click="selectTab(1)">
            <div class="flex justify-center"><svg :fill="dashboardStore.selectedTab == 1 ? '#E3B925' : 'white'"
                    width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">

                    <path
                        d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                </svg></div>
            <div class="font-bold" :class="dashboardStore.selectedTab == 1 ? 'text-[#E3B925]' : 'text-white'">Search
            </div>
        </button>
        <button @click="selectTab(2)">
            <div class="flex justify-center"><svg :fill="dashboardStore.selectedTab == 2 ? '#E3B925' : 'white'"
                    width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                        d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
                </svg></div>
            <div class="font-bold" :class="dashboardStore.selectedTab == 2 ? 'text-[#E3B925]' : 'text-white'">Profile
            </div>
        </button>
        <button @click="selectTab(3)">
            <div class="flex justify-center"><svg :fill="dashboardStore.selectedTab == 3 ? '#E3B925' : 'white'"
                    width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                        d="M21,18V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V6H12C10.89,6 10,6.9 10,8V16A2,2 0 0,0 12,18M12,16H22V8H12M16,13.5A1.5,1.5 0 0,1 14.5,12A1.5,1.5 0 0,1 16,10.5A1.5,1.5 0 0,1 17.5,12A1.5,1.5 0 0,1 16,13.5Z" />
                </svg></div>
            <div class="font-bold" :class="dashboardStore.selectedTab == 3 ? 'text-[#E3B925]' : 'text-white'">Wallet
            </div>
        </button>
    </nav>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
import { useDashboardStore } from '@/store/dashboardStore';


const dashboardStore = useDashboardStore()
const router = useRouter()

const main_color = ref('white')



const setModal = (val, name) => {
    dashboardStore.setModalOpen(val, name)
    if (name == 'register-modal') dashboardStore.setHeader('Sign Up')
    else if (name == 'login-modal' || name == 'forget-modal') dashboardStore.setHeader('Login')
    else if (name == 'profile-modal') { dashboardStore.setSelectedTab(2), dashboardStore.setHeader('Profile') }
    else if (name == 'deposit-modal') { dashboardStore.setSelectedTab(3), dashboardStore.setHeader('Deposit') }
    else if (name == 'search-modal') { dashboardStore.setSelectedTab(1), dashboardStore.setHeader('Search') }
}

function selectTab(tab) {
    dashboardStore.setSelectedTab(tab)

    if (dashboardStore.selectedTab == 0) {
        dashboardStore.setModalOpen(false, '')
        router.push({ name: 'Dashboard' })
    }
    if (dashboardStore.selectedTab == 1) {
        dashboardStore.setHeader('Search')
        dashboardStore.setModalOpen(true, 'search-modal')
        dashboardStore.setGameList('all')
        dashboardStore.setSelectedPV('')
        dashboardStore.setSelectedGT('')
    }
    if (dashboardStore.selectedTab == 2) {
        dashboardStore.setHeader('Profile')

        dashboardStore.setModalOpen(true, 'profile-modal')
    }
    if (dashboardStore.selectedTab == 3) {
        dashboardStore.setHeader('Deposit')

        dashboardStore.setModalOpen(true, 'deposit-modal')
    }



}
</script>

<style scoped>
.bottom-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #252d57;
    padding: 7px 0;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}

.bottom-menu button {
    flex: 1;
    text-align: center;
    border: none;
    background: none;
    font-size: 1rem;
    color: #333;
    cursor: pointer;
}

.bottom-menu button:focus {
    outline: none;
}
</style>